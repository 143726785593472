import React, { useState } from "react";

import Cookies from "universal-cookie";

const ConsentReminderModal = () => {
  const cookies = new Cookies(null, { path: '/' })

  const initialStep = () => {
    const hideReminder = cookies.get('hide-reminder') ?? false;
    const cookiesHandled = cookies.get('__hs_cookie_cat_pref') !== undefined ? true : false;

    if (hideReminder === false && cookiesHandled === true) {
      return 'reminder';
    } else {
      return ''
    }
  }

  const [display, setDisplay] = useState(() => initialStep() !== '');

  const dontShow = (e) => {
    e.preventDefault();
    cookies.set('hide-reminder', true);
    setDisplay(false);
  }

  const showBanner = (e) => {
    e.preventDefault();
    var _hsp = window._hsp = window._hsp || [];
    _hsp.push(['showBanner']);
    setDisplay(false);
  }

  return (
    <div className="modal modal-no-bg" style={{ display: display ? "block" : "none" }}>
      <div className="modal-content bottom consent-reminder">
        <>
          <span className="icon icon-info" />
          <span>
            You can change your <a className="banner-link" href="" onClick={showBanner}>cookie</a> settings at any time
          </span>
          <a className="close-link" href="" onClick={dontShow} >Close and don't show again</a>
        </>
      </div>
    </div>

  );
};

export default ConsentReminderModal;
